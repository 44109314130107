import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutPhoenixCustom"
import BgImg from "../../../images/Hebrews_Big.jpg"
import Hebrews from "../../../images/Hebrews_Square.jpg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Hebrews"
    SecondText="Check out sermons from our Hebrews sermon series"
  >
    <SEO title="Sermons - Hebrews" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
                                    <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="The Superior Life"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/the-superior-life"
        date="July 28th, 2024"
        pastor="Pastor Andrew"
        badge="NEW"
        badgeColor="teal"
      />
                                <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="A New Perspective"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/a-new-perspective"
        date="July 21st, 2024"
        pastor="Scott Trefny"
      />
                            <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="The Disciplined Life"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/the-disciplined-life"
        date="July 14th, 2024"
        pastor="Pastor Andrew"
      />
                        <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="By Faith"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/by-faith"
        date="July 7th, 2024"
        pastor="Pastor Andrew"
      />
            <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Consequences of Change"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/consequences-of-change"
        date="June 30th, 2024"
        pastor="Pastor Andrew"
      />
                    <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="When Enough Isn't Enough"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/when-enough-isnt-enough"
        date="June 23rd, 2024"
        pastor="Monty Patton"
      />
            <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Is Newer Better?"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/is-newer-better"
        date="June 16th, 2024"
        pastor="Pastor Andrew"
      />
        <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Defining Israel"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/defining-israel"
        date="June 9th, 2024"
        pastor="Pastor Andrew"
      />
    <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="A Priest King"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/a-priest-king"
        date="June 2nd, 2024"
        pastor="Pastor Andrew"
      />
     <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Lazy Christianity"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/lazy-christianity"
        date="May 26th, 2024"
        pastor="Pastor Andrew"
      />
            <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="A Beautiful Intercession"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/a-beautiful-intercession"
        date="May 19th, 2024"
        pastor="Pastor Andrew"
      />
        <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="A Time of Rest"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/a-time-of-rest"
        date="May 12th, 2024"
        pastor="Pastor Andrew"
      />
    <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="A Superior Hero"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/a-superior-hero"
        date="May 5th, 2024"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Our Greater Brother"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/our-greater-brother"
        date="April 28th, 2024"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Drifting from Jesus"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/drifting-from-jesus"
        date="April 21st, 2024"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="Jesus Over Angels"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/jesus-over-angels"
        date="April 14th, 2024"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Hebrews}
        imageAlt="Hebrews Sermon Series"
        sermonTitle="An Overview of the Book"
        sermonSeries="Hebrews"
        sermonLink="/phoenix/sermons/hebrews-overview"
        date="April 7th, 2024"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/phoenix/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Phoenix"
      />
    </Grid>
  </Layout>
)

export default IndexPage
